<template>
  <div>
    <div class="about">
      <h1>Logout</h1>
      <div>
        You are no longer logged in at the interface. Click
        <span
        @click="$changeRoute({ name: 'table', params: {dsp: $APPNEXUS }})"
        class="click-here"
        >
          here
        </span>
        to reconnect
      </div>
    </div>
  </div>
</template>

<script>

export default {
  methods: {

  },
  mounted: function () {
    this.$authModule.destroyJwt()
  }

}
</script>

<style>
  .about {
    text-align: center;
    margin-top: 124px;
  }

  .click-here {
    color:cornflowerblue;
    cursor: pointer;
  }
</style>
